import { Button } from "@/components";
import React from "react";

export const Intro: React.FC = () => {
  const scrollTo = (elementId: string) => {
    document.getElementById(elementId.replace('/#/', ''))?.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <div className="bg-delmenhorst w-full h-[40%] md:h-[75%] relative">
      <div className="h-full w-full bg-[#1E2128A0]">
        <div className="max-w-screen-xl m-auto px-[38px] xl:px-0">
          <p className="intro-text text-[32px] leading-[48px] md:text-[64px] md:leading-[84px] text-center md:text-left py-[48px] md:pt-[150px] md:pb-[62px]">
            Shopping<br/>Delmenhorst
          </p>
          <div className="text-center md:text-left">
            <Button shape="circle" onClick={() => scrollTo('veranstaltungen')}>Delmenhorst entdecken</Button>
          </div>
        </div>
      </div>
      <div className="flex w-full max-w-screen-xl m-auto justify-center md:justify-end px-[38px] xl:px-0">
        <a href="https://www.volksbank-oldenburgland-delmenhorst.de/ihrevolksbank/engagement/regional/freewifidel.html" target="_blank" rel="noreferrer">
          <div className="wifi top-[-32px] md:top-[-65px] h-[65px] w-[65px] md:h-[130px] md:w-[130px] flex flex-col justify-center items-center">
            <img src="/images/wifi.png" className="h-[60%] w-[60%]"></img>
          </div>
        </a>
      </div>
    </div>
  );
};
