import { SectionTitle } from "@/components/ui/sectionTitle";
import React from "react";

const images = [
  {
    src: "/images/impressionen/dwfg-impressionen-kartoffelfest.jpg",
    alt: "Kartoffelfest",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-essen-eis.jpg",
    alt: "City & Handel",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-essen-asia.jpg",
    alt: "City & Handel",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-natur-park.jpg",
    alt: "City & Handel",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-kartoffelfest-traktor.jpg",
    alt: "Historischer Traktor",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-natur-see.jpg",
    alt: "City & Handel",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-trinken-bier.jpg",
    alt: "City & Handel",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-kinderanimation.jpg",
    alt: "City & Handel",
  },
  {
    src: "/images/impressionen/dwfg-impressionen-essen-burger.jpg",
    alt: "City & Handel",
  },
];

export const FollowUs: React.FC = () => {
  return (
    <div className="bg-[#FAFAFB]" id="social">
      <div className="flex flex-row max-w-screen-xl m-auto max-h-[720px] md:max-h-[620px]">
        <div className="flex flex-col md:flex-row">
          <div className="md:hidden flex flex-row mt-[50px] space-x-2 overflow-x-scroll px-4">
            {images.map((image, index) => (
              <img
                key={index}
                src={image.src}
                alt={image.alt}
                className="w-[140px] h-[150px] object-cover rounded-[8px]"
              />
            ))}
          </div>
          <div className="w-full md:w-1/2 mt-[24px] py-[24px] md:py-[48px] px-[38px] xl:px-0">
            <SectionTitle className="mb-[28px] md:mb-[42px] justify-start">Follow us</SectionTitle>
            <div className="max-w-[465px] text-[14px] md:text-[18px]">
              <div className="mt-[12px] mb-[20px]">
                <a href="https://www.instagram.com/delmenhorstmittendrin/" target="_blank" rel="noreferrer" className="text-[#37750C] hover:underline">
                  <strong className="text-[16px] md:text-[18px]">@delmenhorstmittendrin</strong>
                </a>
                <p>
                  Stadtwandel: Revitalisierung der Delmenhorster Innenstadt 💚, Leerstände füllen, Zukunft gestalten und die Gemeinschaft stärken🌏🏬🏡
                </p>  
              </div>
              <div className="mt-[12px] mb-[20px]">
                <a href="https://www.instagram.com/delmenhorst_entdecken/" target="_blank" rel="noreferrer" className="text-[#37750C] hover:underline">
                  <strong className="text-[16px] md:text-[18px]">@delmenhorst_entdecken</strong>
                </a>
                <p>
                Die offiziellen Social Media-Accounts der dwfg! Marketing & Tourismus / Citymanagement... #Delmenhorst ist unsere Leidenschaft ❤️ <br />
                👇🏼 Guck‘ doch mal rein
                </p>
                <a href="https://www.instagram.com/delmenhorst_entdecken/" target="_blank" rel="noreferrer">
                  <div className="flex flex-col h-[44px] w-[44px] rounded-full bg-[#171A1F] justify-center items-center mt-4">
                    <img src="/images/icon-instagram.png" alt="Instagram" className="w-[24px] h-[24px] inline-block" />
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex flex-row space-x-6 overflow-hidden">
            <div className="hidden md:block space-y-6">
              <img src={images[0].src} alt={images[0].alt} className="w-full h-[238px] object-cover rounded-[8px] mt-[-48px]" />
              <img src={images[1].src} alt={images[1].alt} className="w-full h-[238px] object-cover rounded-[8px]" />
              <img src={images[2].src} alt={images[2].alt} className="w-full h-[238px] object-cover rounded-[8px]" />
            </div>
            <div className="hidden md:block space-y-6">
              <img src={images[3].src} alt={images[3].alt} className="w-full h-[238px] object-cover rounded-[8px] mt-[-120px]" />
              <img src={images[4].src} alt={images[4].alt} className="w-full h-[238px] object-cover rounded-[8px]" />
              <img src={images[5].src} alt={images[5].alt} className="w-full h-[238px] object-cover rounded-[8px]" />
            </div>
            <div className="hidden lg:block space-y-6">
              <img src={images[6].src} alt={images[6].alt} className="w-full h-[238px] object-cover rounded-[8px] mt-[-34px]" />
              <img src={images[7].src} alt={images[7].alt} className="w-full h-[238px] object-cover rounded-[8px]" />
              <img src={images[8].src} alt={images[8].alt} className="w-full h-[238px] object-cover rounded-[8px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
