import React from "react";

export const Slogan: React.FC = () => {
  return (
    <div className="py-[75px] md:py-[120px] text-center p-5">
      <p>
        <strong className="text-sm md:text-md">Herzlich willkommen in der Delmenhorster Innenstadt!</strong>
      </p>
      <p className="text-sm md:text-md mt-[16px] max-w-screen-md m-auto">Auf dieser Website finden Sie alles rund um unsere Innenstadt: Aktuelle Veranstaltungen, nützliche Links, direkte Kontaktmöglichkeiten zum Innenstadt-Team sowie auch eine Übersicht über verfügbare Gewerbeobjekte. Entdecken Sie, was Delmenhorst zu bieten hat.</p>
    </div>
  );
};
