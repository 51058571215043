import { Button } from "@/components";
import { SectionTitle } from "@/components/ui/sectionTitle";
import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const CityHandel: React.FC = () => {
  const carouselSettings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    dotsClass: "city-dots",
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div id="city"></div>
      <div className="mt-[72px] md:mt-[120px] max-w-screen-xl m-auto" id="city">
        <div className="flex flex-col-reverse md:flex-row px-[38px] xl:px-0">
          <div className="w-full md:w-1/2">
            <SectionTitle className="hidden md:inline-block mb-[28px]">City & Handel</SectionTitle>
            <div className="max-w-[462px] text-[18px] md:text-[24px]">
              <p>
                Wir beraten Sie gerne, wenn Sie ein Geschäft in der Delmenhorster Innenstadt eröffnen möchten. 
              </p>
              <p>
                Aktuelle Angebote in der Vermietung finden Sie zudem unter: 
              </p>

              <a href="https://www.komsis.de/de/gewerbeimmobilien/?" target="_blank" rel="noreferrer" className="w-full inline-block my-[36px]">
                <Button shape="circle" className="w-full md:w-fit">Leerstandskataster komsis</Button>
              </a>
            </div>
            
            <a href="https://dwfg.de/city-handel.html" target="_blank" rel="noreferrer">
              <div className="flex flex-row md:mt-20 space-x-2">
                <img src="/images/dwfg/dwfg-website_Hoffmann-Connor.png" alt="Connor Hoffmann" className="rounded-full h-[64px] w-[64px] hover:border-2 border-[#37750C] cursor-pointer" />
                <img src="/images/dwfg/dwfg-website_Jansen-Lisa.png" alt="Lisa Jansen" className="rounded-full h-[64px] w-[64px] hover:border-2 border-[#37750C] cursor-pointer" />
                <img src="/images/dwfg/dwfg-website_Lutz-Nadine.png" alt="Nadine Lutz" className="rounded-full h-[64px] w-[64px] hover:border-2 border-[#37750C] cursor-pointer" />
                <img src="/images/dwfg/dwfg-website_Wegeris-Hendrik.png" alt="Hendrik Wegeris" className="hidden md:inline-block rounded-full h-[64px] w-[64px] hover:border-2 border-[#37750C] cursor-pointer" />
                <div className="flex flex-col rounded-full h-[64px] w-[64px] bg-[#F1FDE9] cursor-pointer justify-center items-center hover:border-2 border-[#37750C]">
                  <img src="/images/chevron-right.svg" alt="More" className="rounded-full h-[32px] w-[32px] cursor-pointer text-[#37750C]" />
                </div>
              </div>
            </a>
          </div>
          <div className="w-full md:w-1/2">
            <SectionTitle className="md:hidden mb-[28px]">City & Handel</SectionTitle>
            <Slider {...carouselSettings} className="rounded-[8px]">
              <img src="/images/LangeStraße.jpg" alt="Weinfest" className="w-full object-cover rounded-[8px] mb-[16px] md:mb-[28px] h-[240px] md:h-[520px]" />  
              <img src="/images/Kartoffelfest.jpg" alt="City & Handel" className="w-full object-cover rounded-[8px] mb-[16px] md:mb-[28px] h-[240px] md:h-[520px]" />  
              <img src="/images/Weinfest.jpg" alt="Weinfest" className="w-full object-cover rounded-[8px] mb-[16px] md:mb-[28px] h-[240px] md:h-[520px]" />  
              <img src="/images/Lichterfest.jpg" alt="Lichterfest" className="w-full object-cover rounded-[8px] mb-[16px] md:mb-[28px] h-[240px] md:h-[520px]" />  
              <a href="https://www.hyrst.de/" target="_blank" rel="noreferrer">
                <img src="/images/Hyrst.jpg" alt="Hyrst" className="w-full object-cover rounded-[8px] mb-[16px] md:mb-[28px] h-[240px] md:h-[520px]" />  
              </a>
              <a href="https://dwfg.de/tourist-information.html" target="_blank" rel="noreferrer">
                <img src="/images/Touristinfo.jpg" alt="Tourist Information" className="w-full object-cover rounded-[8px] mb-[16px] md:mb-[28px] h-[240px] md:h-[520px]" />  
              </a>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};
